import React from 'react';
import * as most from 'most';

import { doubleDigits, createUnmountSignal } from '../util';

import styles from './countdown.module.css';

const ticker$ = most
  .periodic(1000)
  .multicast()

export class Countdown extends React.Component {
  static defaultProps = {
    accessibilityrole: 'timer',
    fixed: false,
    separator: ' : ',
    short: false,
  }

  componentWillMount () {
    this.update();
  }

  componentDidMount () {
    ticker$.until(createUnmountSignal(this)).observe(() => this.update());
  }

  componentWillReceiveProps (nextProps) {
    this.update(nextProps);
  }

  update ({ to } = this.props) {
    const now = Date.now()
    const delta = !to ? 0 : Math.max(0, Math.round((new Date(to) - now) / 1000));
    const hours = Math.floor(delta / 3600);
    const minutes = Math.floor(delta / 60) - hours * 60;
    const seconds = delta - minutes * 60 - hours * 3600;

    this.setState({ hours, minutes, seconds });
  }

  renderDigit = (digit, index) => {
    const { textStyle } = this.props;

    return (
      <div key={index} style={textStyle} className={styles.digit}>
        {digit}
      </div>
    );
  }

  render () {
    const { className, fixed, separator, short, textClassName, to, ...props } = this.props;
    const { hours, minutes, seconds } = this.state;
    const expired = hours === 0 && minutes === 0 && seconds === 0;

    if (fixed) {
      const time = expired ?
        ['--', separator, '--', separator, '--'] :
        [doubleDigits(hours), separator, doubleDigits(minutes), separator, doubleDigits(seconds)];

      const children = time.map((item, index) => {
        if (item === separator) {
          return (
            <div
              key={`separator-${index}`}
              className={[styles.separator, textClassName].join(' ')}
            >
              {item}
            </div>
          );
        } else {
          return item.split('').map((digit, i) => (
            <div key={`digit-${index}-${i}`} className={styles.digit}>
              <div className={textClassName}>{digit}</div>
            </div>
          ));
        }
      });

      return (
        <div {...props} className={[styles.fixed, className].join(' ')}>
          {children}
        </div>
      );
    }

    if (expired) {
      return (
        <div {...props} className={textClassName}>
          {['--', '--', '--'].join(separator)}
        </div>
      );
    }

    const parts = [doubleDigits(seconds)];

    if (hours || !short) {
      parts.unshift(doubleDigits(minutes));
      parts.unshift(hours);
    } else {
      parts.unshift(minutes);
    }

    return (
      <div {...props} className={textClassName}>
        {parts.join(separator)}
      </div>
    );
  }
}
