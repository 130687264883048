import axios from 'axios';

import { API_BASE_URL } from '../config';
import { deserialize } from '../util';

const API_BASE_PATH = '/api/v2.0';

const DEFAULT_CONFIG = {
  timeout: 30000,
  transformResponse: [attemptToDeserialize],
};

export let authToken = null;
export let baseURL = `${API_BASE_URL}${API_BASE_PATH}`;

export const request = axios.create({
  ...DEFAULT_CONFIG,
  baseURL,
});

function attemptToDeserialize (data) {
  try {
    return deserialize(data);
  } catch (e) {
    return data;
  }
}
