import React from 'react'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'

import { Container } from './container'
import fonts from '../fonts'

const Wrapper = styled(Container)`
  font-family: Gotham;
`;

export const Layout = ({ children }) => (
  <Wrapper>
    <Global
      styles={css`
        @font-face {
          font-family: "Gotham";
          font-style: normal;
          font-weight: 900;
          src: local("Gotham Black"), url(${fonts.GothamBlack}) format("opentype")
        }
      
        @font-face {
          font-family: "Gotham";
          font-style: normal;
          font-weight: bold;
          src: local("Gotham Bold"), url(${fonts.GothamBold}) format("opentype")
        }
      
        @font-face {
          font-family: "Gotham";
          font-style: normal;
          font-weight: normal;
          src: local("Gotham"), url(${fonts.Gotham}) format("opentype")
        }
      
        @font-face {
          font-family: "Gotham";
          font-style: normal;
          font-weight: 500;
          src: local("Gotham Medium"), url(${fonts.GothamMedium}) format("opentype")
        }
      `}
    />
    {children}
  </Wrapper>
)