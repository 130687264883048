import React from 'react'

import { Container } from './container'
import { Spacer } from './spacer'

import styles from './header.module.css'
import localization from '../localization'
export class Header extends React.Component {
  render() {
    const { className, fontSizeMultiplier, language, style, ...props } = this.props;
    const text = localization[language];
    return (
      <Container
        style={style}
        className={[className, styles.headerContainer].join(' ')}
        {...props}
      >
        <div className={styles.mainHeaderSmall}>
          {text.header1}
        </div>
        <div className={[styles.mainHeaderLarge, styles[`${language}MainHeaderLarge`]].join(' ')}>
          {text.header2}
        </div>
        <Spacer height={'2.0vmin'} />
        <div className={styles.mainHeaderSmall}>
          {text.header3}
        </div>
      </Container>
    )
  }
}
