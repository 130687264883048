import React from 'react'

import { Container } from './container'

import styles from './footer.module.css'
import localization from '../localization'
export class Footer extends React.Component {
  render() {
    const { className, language, ...props } = this.props;
    const text = localization[language];

    return (
      <Container
        className={[styles.footer, className].join(' ')}
        {...props}
      >
        {text.poweredBy}
        <div className={styles.logoImage}/>
      </Container>
    )
  }
}