import React from 'react'

import styles from './falling-circle.module.css'

export class FallingCircle extends React.Component {
  render() {
    const { style, className, ...props } = this.props;
    return (
      <div
        className={[styles.circle, className].join(' ')}
        style={style}
        {...props}
      />
    )
  }
}