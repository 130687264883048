import fastMemoize from 'fast-memoize';
import LRUCache from 'lru-cache';

const DEFAULT_OPTIONS = {
  max: 1000, // Avoid unbounded memory usage by default.
};

export function memoize (fn, { serializer, ...options } = {}) {
  return fastMemoize(fn, {
    cache: {
      create: () => {
        return new LRUCache({ ...DEFAULT_OPTIONS, ...options });
      }
    },
    serializer,
  });
}

/*
class MemoizeCache extends LRUCache {

  set (key, value) {
    super.set(key, value);

    // If the value is Promise-like, then remove from cache on error.
    if (value && typeof value === 'object' && typeof value.then === 'function') {
      value.then(null, () => {
        if (this.peek(key) === value) {
          this.del(key);
        }
      });
    }
  }
}
*/