export const jackpotSizeSelector = (jackpots, lottery) => {
  const jackpot = jackpots[lottery];
  if (jackpot && jackpot.next) {
    return jackpot.next.jackpot;
  }
}

export const lastDrawDateSelector = (jackpots, lottery) => {
  const jackpot = jackpots[lottery];
  if (jackpot && jackpot.last) {
    return jackpot.last.resultsAnnouncedAt;
  }
}

export const nextDrawDateSelector = (jackpots, lottery) => {
  const jackpot = jackpots[lottery];
  if (jackpot && jackpot.next) {
    return jackpot.next.resultsAnnouncedAt;
  }
}

export const winningNumbersSelector = (jackpots, lottery) => {
  const jackpot = jackpots[lottery];
  if (jackpot && jackpot.last) {
    return jackpot.last.numbers;
  }
  return ['--', '--', '--', '--', '--', '--'];
}
