const MONTHS = [
  'January', 'February', 'March', 'April',
  'May', 'June', 'July', 'August', 'September', 'October',
  'November', 'December',
];

const DAYS = [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday',
  'Thursday', 'Friday', 'Saturday',
];

const toDate = (date) => date instanceof Date ? date : new Date(date);

export function doubleDigits (n) {
  return (n < 10 ? '0' : '') + +n;
}

export function getDayOfWeek (date) {
  date = toDate(date);
  return DAYS[date.getDay()];
}

export function getFriendlyMonth (monthNumber, short = false) {
  return short ? MONTHS[monthNumber].slice(0, 3) : MONTHS[monthNumber];
}

export function getHumanFriendlyDate (date) {
  date = toDate(date);

  const month = getFriendlyMonth(date.getMonth());
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

export function getHumanFriendlyTime (date) {
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${(hour % 12) || 12}:${doubleDigits(minute)} ${hour < 12 ? 'AM' : 'PM'}`;
}

export function getShortDate (date) {
  const month = doubleDigits(date.getMonth() + 1);
  const day = doubleDigits(date.getDate());
  const year = String(date.getFullYear());

  return `${day}/${month}/${year}`;
}

export function isSameDate (lhs, rhs) {
  return (
    lhs.getFullYear() === rhs.getFullYear() &&
    lhs.getMonth() === rhs.getMonth() &&
    lhs.getDate() === rhs.getDate()
  );
}
