export default {
  el: {
    header1: 'ΠΡΑΓΜΑΤΙΚΟ ΑΜΕΡΙΚΑΝΙΚΟ',
    header2: 'ΠΙΣΤΟΠΟΙΗΤΙΚΑ POWERBALL',
    header3: 'ΔΙΑΘΕΣΙΜΟ ΓΙΑ ΑΓΟΡΑ',
    currentJackpot: 'ΤΡΕΧΟΝ ΤΖΑΚΠΟΤ',
    nextDrawing: 'ΕΠΟΜΕΝΟ ΣΧΕΔΙΟ',
    countdownToDrawing: 'ΧΡΟΝΟΣ ΕΩΣ',
    winningNumbersFrom: 'ΚΥΚΛΟΦΟΡΙΑΚΟΙ ΑΡΙΘΜΟΙ ΑΠΟ',
    poweredBy: 'Παρέχεται από',
    million: 'ΕΚΑΤΟΜΜΥΡΙΑ',
    billion: 'ΔΙΣΕΚΑΤΟΜΜΥΡΙΟ',
  },
  en: {
    header1: 'OFFICIAL UNITED STATES',
    header2: 'POWERBALL TICKETS',
    header3: 'AVAILABLE FOR PURCHASE',
    currentJackpot: 'CURRENT JACKPOT',
    nextDrawing: 'NEXT DRAWING',
    countdownToDrawing: 'COUNTDOWN TO DRAWING',
    winningNumbersFrom: 'WINNING NUMBERS FROM',
    poweredBy: 'Powered by',
    million: 'MILLION',
    billion: 'BILLION',
  },
  tr: {
    header1: 'GERÇEK AMERİKAN',
    header2: 'POWERBALL BİLET',
    header3: 'SATINALINABİLİR',
    currentJackpot: 'GÜNCEL NAKİT ÖDÜLÜ',
    nextDrawing: 'SONRAKİ ÇİZİM',
    countdownToDrawing: 'ZAMANLI ZAMAN',
    winningNumbersFrom: 'DAN KAZANAN NUMARALAR',
    poweredBy: 'Tarafından sunulan',
    million: 'MİLYON',
    billion: 'MİLYAR',
  },
}