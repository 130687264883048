import React from 'react'

import { Container } from '../container'
import { FallingCircle } from './falling-circle'

import styles from './falling-circle-view.module.css'

export class FallingCirclesView extends React.Component {
  render() {
    const { className, ...props } = this.props;

    return (
      <Container className={[styles.content, className].join(' ')} {...props}>
        {[1,2,3,4].map((i) => (
          <FallingCircle
            key={`circle${i}`}
            className={styles[`circle${i}`]}
          />
        ))}
      </Container>
    )
  }
}
