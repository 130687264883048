import * as most from 'most';
import { sync } from 'most-subject';
import { hold } from '@most/hold';

const PROPS_STREAM_KEY = Symbol();
const UPDATE_STREAM_KEY = Symbol();
const UNMOUNT_SIGNAL_KEY = Symbol();

export function createActivityStream (eventName, emitter, upstream$, ignore$) {
  return most.fromEvent(eventName, emitter)
    .scan((count, visible) => count + (visible ? 1 : -1), 0)
    .combine((count, ignore) => count > ignore, ignore$ || most.just(0))
    .combine((a, b) => a || b, upstream$ || most.just(false))
    .skipRepeats()
    .thru(makeHotStream);
}

export function createPropsStream (component) {
  let stream = component[PROPS_STREAM_KEY];
  if (stream) {
    return stream;
  }

  const { componentWillReceiveProps } = component;
  const subject = sync();

  component.componentWillReceiveProps = (nextProps) => {
    subject.next(nextProps);

    if (componentWillReceiveProps) {
      componentWillReceiveProps.call(component, nextProps);
    }
  };

  stream = most.just(component.props)
    .concat(subject)
    .thru(makeHotStream)
    .until(createUnmountSignal(component));

  return (component[PROPS_STREAM_KEY] = stream);
}

export function createUpdateStream (component) {
  let stream = component[UPDATE_STREAM_KEY];
  if (stream) {
    return stream;
  }

  const { componentDidUpdate } = component;
  const subject = sync();

  component.componentDidUpdate = (...args) => {
    subject.next(component.props);

    if (componentDidUpdate) {
      componentDidUpdate.apply(component, args);
    }
  };

  stream = most.just(component.props)
    .concat(subject)
    .thru(makeHotStream)
    .until(createUnmountSignal(component));

  return (component[UPDATE_STREAM_KEY] = stream);
}

export function createUnmountSignal (component) {
  const signal = component[UNMOUNT_SIGNAL_KEY];
  if (signal) {
    return signal;
  }

  const { componentWillUnmount } = component;
  const subject = sync();

  component.componentWillUnmount = () => {
    subject.next();
    subject.complete();

    if (componentWillUnmount) {
      component.componentWillUnmount = componentWillUnmount;
      component.componentWillUnmount();
    }
  };

  return (component[UNMOUNT_SIGNAL_KEY] = subject.multicast());
}

export function makeHotStream (stream) {
  stream = stream.thru(hold);
  stream.drain();

  return stream;
}
