import localization from '../localization'

export function formatMoney (amount, short = false) {
  return `$${formatNumber(short ? Math.floor(amount / 100) : (amount / 100).toFixed(2))}`;
}

export function formatNumber (number, precision = 0) {
  if (precision) {
    // Remove trailing zeros.
    number = number
      .toPrecision(precision)
      .replace(/(\.\d*?)0+$/, '$1')
      .replace(/\.$/, '');
  } else if (number.toFixed) {
    // Remove decimal value.
    number = number.toFixed(0);
  }

  // Add commas.
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatPrize (prize, language = 'en') {
  if (prize >= 999500000) {
    const billions = prize / 1000000000;
    return `$${formatNumber(billions, 3)} ${localization[language]['billion']}`;
  }

  if (prize >= 999500) {
    const millions = prize / 1000000;
    return `$${formatNumber(millions, 3)} ${localization[language]['million']}`;
  }

  return `$${formatNumber(prize)}`;
}
