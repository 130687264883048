import React from 'react'

import styles from './ball.module.css'

export class Ball extends React.Component {
  render() {
    const {
      className,
      color,
      disabled,
      number,
      size,
      ...props
    } = this.props;

    const ballClass = [
      styles.ball,
      styles[color],
      className,
      disabled && styles[`${color}Disabled`]
    ].join(' ');

    const textClass = [
      styles.text,
      styles[`${color}Text`],
    ].join(' ');

    return (
      <div
        className={ballClass}
        {...props}
      >
        <div 
          className={textClass}
        >
          {number}
        </div>
      </div>
    )
  }
}
