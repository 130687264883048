import * as api from '../api';

export const getLastResults = (lottery) => async (dispatch) => {
  try {
    const {
      jackpot,
      numbers,
      resultsAnnouncedAt
    } = await api.getLastResults(lottery);

    dispatch({
      type: 'UPDATE_JACKPOTS',
      payload: { date: 'last', jackpot, lottery, numbers, resultsAnnouncedAt }
    })
  } catch (e) {
    console.log('Something went wrong while getting the last results', e);
  }
}

export const getNextResults = (lottery) => async (dispatch) => {
  try {
    const {
      jackpot,
      resultsAnnouncedAt
    } = await api.getNextResults(lottery);

    dispatch({
      type: 'UPDATE_JACKPOTS',
      payload: { date: 'next', jackpot, lottery, resultsAnnouncedAt }
    });
  } catch (e) {
    console.log('Something went wrong while getting the next results', e);
  }
}

export const getResults = (lottery) => (dispatch) => {
  dispatch(getLastResults(lottery));
  dispatch(getNextResults(lottery));
}
