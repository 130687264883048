import React from 'react'

import { connect } from 'react-redux'

import {
  Ball,
  Container,
  Countdown,
  Footer,
  Header,
  HorizontalLine,
  InfoSectionContainer,
  Spacer,
  VerticalLine
} from './'

import {
  jackpotSizeSelector,
  lastDrawDateSelector,
  nextDrawDateSelector,
  winningNumbersSelector
} from '../selectors'

import { formatPrize, getShortDate } from '../util'

import styles from './lottery-widget.module.css'
import localization from '../localization'

const LOTTERY = 'powerball';

class LotteryWidgetBase extends React.Component {
  render() {
    const { className, jackpot, language, lastDrawDate, nextDrawDate, numbers, ...props } = this.props;

    const text = localization[language];

    return (
      <Container className={[styles.outerContainer, className].join(' ')} {...props}>
        <div className={styles.pageContainer}>
          <Header className={styles.header} language={language}/>

          <InfoSectionContainer className={styles.infoSection}>
            <Spacer height={'3vmin'} />
            <div className={styles.infoHeader}>
              {text.currentJackpot}
            </div>
            <div className={[styles.infoData, styles.infoJackpotSize, styles[`${language}InfoJackpotSize`]].join(' ')}>
              {jackpot ? formatPrize(jackpot, language) : '--'}
            </div>
            <Spacer height={'3vmin'} />
            <HorizontalLine className={styles.horizontalLine} />
            <div className={styles.infoRow}>
              <div className={styles.infoColumn}>
                <div className={styles.infoHeader}>
                  {text.nextDrawing}
                </div>
                <div className={styles.infoData}>
                  {getShortDate(nextDrawDate)}
                </div>
              </div>
              <VerticalLine className={styles.verticalLine} />
              <div className={styles.infoColumn}>
                <div className={styles.infoHeader}>
                  {text.countdownToDrawing}
                </div>
                <div className={styles.infoData}>
                  <Countdown to={nextDrawDate} textClassName={styles.countdown}/>
                </div>
              </div>
            </div>
          </InfoSectionContainer>

          <InfoSectionContainer className={[styles.infoSection, styles.numbersSection].join(' ')}>
            <div className={styles.infoHeader}>
              {`${text.winningNumbersFrom} ${getShortDate(lastDrawDate)}`}
            </div>
            <div className={styles.ballContainer}>
              {numbers.map((n, i) => (
                <Ball
                  key={`ball_${i}`}
                  color={i===5 ? 'red' : 'white'}
                  number={n}
                />
              ))}
            </div>
          </InfoSectionContainer>
          <Footer language={language}/>
        </div>
      </Container>
    )
  }
}

export const LotteryWidget = connect(
  ({ jackpots }) => ({
    jackpot: jackpotSizeSelector(jackpots, LOTTERY),
    lastDrawDate: lastDrawDateSelector(jackpots, LOTTERY) || new Date(),
    nextDrawDate: nextDrawDateSelector(jackpots, LOTTERY) || new Date(),
    numbers: winningNumbersSelector(jackpots, LOTTERY),   
  })
)(LotteryWidgetBase);