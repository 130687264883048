import React from 'react'
import * as most from 'most'
import { connect } from 'react-redux'
import { css, keyframes } from '@emotion/core'

import { getResults } from '../actions'

import {
  FallingCirclesView,
  Layout,
  LotteryWidget,
} from '../components'

import styles from './index.module.css'
import { createUnmountSignal } from '../util';

const LOTTERY = 'powerball';
const LOOP_SECONDS = 30;
const LANGUAGES = ['en', 'el', 'tr'];
const NUM_WIDGETS = LANGUAGES.length;

const WIDGET_ANIM = keyframes`
  from, 40%, to   { opacity: 0; }
  5%, 35% { opacity: 1; }
`

const loadDataTimer$ = most
  .periodic(1000 * 60)
  .multicast();

class IndexPage extends React.Component {

  componentDidMount() {
    const { getResults } = this.props;
    getResults(LOTTERY);

    const unmount$ = createUnmountSignal(this);
    loadDataTimer$
      .takeUntil(unmount$)
      .observe(() => getResults(LOTTERY));
  }

  getWidgetProps() {
    return LANGUAGES.map((language, i) => ({
      language,
      style: css`
        animation: ${WIDGET_ANIM} ${LOOP_SECONDS}s ${i * LOOP_SECONDS / NUM_WIDGETS}s infinite;
        opacity: ${i === 0 ? 1 : 0};
      `
    }))
  }

  render() {
    const widgetProps = this.getWidgetProps();

    return (
      <Layout>
        {widgetProps.map(({ language, style }, i) => (
          <LotteryWidget
            key={`widget_${i}`}
            className={styles.widget}
            language={language}
            css={style}
          />
        ))}
        <FallingCirclesView className={styles.background} />
      </Layout>
    );
  };
}

export default connect(
  () => ({}),
  { getResults },
)(IndexPage);
