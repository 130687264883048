import React, { Component } from 'react'
import styles from './container.module.css'

export class Container extends Component {
  render () {
    const {
      className,
      children,
      ...props
    } = this.props;

    return (
      <div
        className={[styles.container, className].join(' ')}
        {...props}
      >
        {children}
      </div>
    )
  }
}