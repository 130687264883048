import React from 'react'

import { Container } from './container'

import styles from './info-section-container.module.css'

export class InfoSectionContainer extends React.Component {
  render() {
    const { children, className, ...props } = this.props;
    return (
      <Container
        className={[styles.infoSectionContainer, className].join(' ')}
        {...props}
      >
        {children}
      </Container>
    )
  }
}