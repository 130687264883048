import { request } from './request'

export const getLastResults = async (lottery) => {
  const { data } = await request.get(`/games/${lottery}/results/last`);

  if (!data) {
    return {};
  }

  const { results, prizes } = data;
  const { resultsAnnouncedAt } = data;
  
  const jackpot = prizes && prizes.values.find(v => v.type === 'JACKPOT').value;

  const specialNumber = results && results.values.find(v => v.category === 'EXTRA');

  const numbers = results && results.values
    .filter(v => v.category !== 'BONUS' && v.category !== 'EXTRA')
    .sort((a, b) => a - b)
    .concat(specialNumber)
    .map(v => v.value);

  return {
    jackpot,
    numbers,
    resultsAnnouncedAt
  }
}

export const getNextResults = async (lottery) => {
  const { data } = await request.get(`/games/${lottery}/results/next`);

  if (!data) {
    return {};
  }
  const { prizes, resultsAnnouncedAt } = data;
  const jackpot = prizes && prizes.values.find(v => v.type === 'JACKPOT').value;

  return {
    jackpot,
    resultsAnnouncedAt
  }
}
