import React from 'react'

export class Spacer extends React.Component {
  render() {
    const { children, height, width, ...props } = this.props;
    const style = {
      height,
      width,
    }
    
    return (
      <div
        style={style}
        {...props}
      >
        {children}
      </div>
    )
  }
}

Spacer.defaultProps = {
  height: 0,
  width: 0,
}