import GothamBold from './Gotham-Bold.otf'
import GothamBlack from './Gotham-Black.otf'
import Gotham from './Gotham.otf'
import GothamMedium from './Gotham-Medium.otf'

export default {
  GothamBold,
  GothamBlack,
  Gotham,
  GothamMedium
};
