import { memoize } from './memoize';

const DRAW_DATE_REGEX = /^\d{2}\/\d{2}\/\d{2}$/;

const TYPE_NAME_KEY = '__type__';
const TYPE_DECODERS = Object.create(null);
const TYPE_ENCODERS = new Map();

// Memoize Date objects so downstream shallow equality holds true.
// The memory and performance cost is extremely low, even for thousands of dates.
const parseDate = memoize((value) => new Date(value), { max: 10000 });

function transformDeserializedData (data) {
  if (!data || typeof data !== 'object') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(transformDeserializedData);
  }

  // Handle specially registered types.
  const typename = data[TYPE_NAME_KEY];
  if (typename) {
    const decoder = TYPE_DECODERS[typename];

    if (!decoder) {
      console.warn(`Unable to deserialize object of type: ${typename}`);
      return undefined;
    }

    return decoder(data.value);
  }

  const result = {};

  for (const key in data) {
    let value = transformDeserializedData(data[key]);
    
    // eslint-disable-next-line
    switch (key) {
    case 'acknowledgedWinnings':
      if (!(value instanceof Set)) {
        value = new Set(value);
      }
      break;
    case 'drawDate': {
      // WORKAROUND: The drawDate is provided without timezone information (MM/DD/YY).
      if (typeof value === 'string' && DRAW_DATE_REGEX.test(value)) {
        const [month, day, year] = value.split('/');

        // Assume PB/MM draw time of 10:59pm EST (works date-wise when on EDT).
        value = parseDate(`20${year}-${month}-${day}T22:59:00.000-05:00`);
        break;
      }
    } // Fallthrough...
    case 'createdAt':
    case 'date':
    case 'dateOfAcceptedPayluckyToS':
    case 'drawStartTime':
    case 'drawTime':
    case 'expires':
    case 'expiresAt':
    case 'lastGameDate':
    case 'nextGameDate':
    case 'refreshedAt':
    case 'resultsAnnouncedAt':
    case 'updatedAt':
      if (!(value instanceof Date)) {
        value = parseDate(value);
      }
      break;
    }

    result[key] = value;
  }

  return result;
}

function transformRawData (data) {
  if (!data || typeof data !== 'object') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(transformRawData);
  }

  // Handle specially registered types.
  const { constructor } = data;
  const { encoder, typeName } = TYPE_ENCODERS.get(constructor) || {};

  if (encoder && typeName) {
    return {
      [TYPE_NAME_KEY]: typeName,
      value: encoder(data),
    };
  }

  const result = {};

  for (const key in data) {
    result[key] = transformRawData(data[key]);
  }

  return result;
}

export function deserialize (data) {
  if (typeof data === 'string') {
    data = JSON.parse(data);
  }

  return transformDeserializedData(data);
}

export function serialize (data) {
  return JSON.stringify(transformRawData(data));
}

export const registerSerializable = (typeName, options = {}) => (constructor) => {
  const { decode, encode } = options;

  TYPE_DECODERS[typeName] = decode || (x => new constructor(x));
  TYPE_ENCODERS.set(constructor, {
    encoder: encode || (x => x.toJSON ? x.toJSON() : x.valueOf()),
    typeName,
  });

  return constructor;
};

// Register some default transforms...
registerSerializable('Date', {
  decode: parseDate,
  encode: date => date.getTime(), // More efficient than ISO strings.
})(Date);

registerSerializable('Set', {
  encode: set => [...set],
})(Set);
